export const SYSTEM_PALETTE = {
  primary: "#6f4c97",
  secondary: "#2b4a6c",
  success: "#41AB64",
  info: "#51829B",
  accent: "#51829B",
  warning: "#ec9943",
  error: "#D06262",
  linkedin: "#0072b1",
  "body-text": "#676b94",
  headline: "#100b47",
  background: "#f6f4ff",
  "gradient-blue": "#4a90e2",
  "gradient-purple": "#9013fe",
  "gradient-yellow": "#f8e71c",
};
