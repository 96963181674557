<template>
  <div class="dashboard-notifications rounded">
    <DashboardNotification
      class="mt-1"
      v-for="notification in notifications"
      :key="notification._id"
      :message="notification.message"
      :title="notification.title"
      :color="notification.color"
      :action="notification.action"
      :notificationId="notification._id"
    />
  </div>
</template>

<script>
import { useWebSocket } from "@/socket";
import DashboardNotification from "./DashboardNotification";
export default {
  data() {
    return {};
  },
  computed: {
    notifications() {
      return this.$store.state.dashboardNotifications.notifications;
    },
  },
  created() {
    this.$store.dispatch("dashboardNotifications/fetchNotifications");
  },
  mounted() {
    const sockets = useWebSocket();

    const user = this.$store.getters["auth/getUser"];

    const socket = sockets.connect("/notifications/user", user._id);

    if (socket) {
      const self = this;

      socket.on("notification:created", (notification) => {
        self.$store.dispatch(
          "dashboardNotifications/addNotification",
          notification
        );
      });
    }
  },
  methods: {},
  components: { DashboardNotification },
};
</script>

<style lang="scss">
.dashboard-notifications {
  max-height: 200px;
  overflow-y: auto;
}
</style>
