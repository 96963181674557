<template>
  <v-navigation-drawer
    @transitionend="onTransition"
    app
    :class="{
      'hide-border': !isAdminPanel,
      'elevation-2': elevate,
    }"
    style="padding-top: 64px"
    width="300"
    expand-on-hover
    permanent
  >
    <v-list class="pt-0" style="margin-bottom: 60px">
      <NavigationItem
        v-for="(entry, index) in items"
        :key="index"
        :entry="entry"
      />
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import NavigationItem from "./NavigationItem.vue";
import { ADMIN_NAV_ITEMS, DEFAULT_NAV_ITEMS } from "./data";

export default {
  components: {
    NavigationItem,
  },
  data() {
    return {
      elevate: false,
    };
  },
  methods: {
    /**
     * Triggered when transition finish
     * @param {TransitionEvent} ev
     */
    onTransition(ev) {
      this.elevate = ev.target.classList.contains(
        "v-navigation-drawer--is-mouseover"
      );
    },
  },
  computed: {
    /**
     * Is current view
     */
    isAdminPanel() {
      return this.$route.path.includes("admin");
    },
    /**
     * Items for the navigation
     */
    items() {
      if (this.$route.path.includes("admin")) {
        return ADMIN_NAV_ITEMS;
      }

      return DEFAULT_NAV_ITEMS;
    },
  },
};
</script>

<style lang="scss">
.navigation-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;

  &__image {
    width: 120px;
  }
}

.hide-border {
  & .v-navigation-drawer__border {
    display: none !important;
  }
}

.v-navigation-drawer {
  & a.v-list-item--active {
    background-color: var(--primary-10);
    color: var(--primary) !important;

    &::before {
      opacity: 0;
    }
  }
}

.v-list-item {
  transition: all 0.1s ease;
}

.v-navigation-drawer--is-mouseover {
  .v-list-item:hover {
    background-color: var(--primary-lighten-1-25);

    &::before {
      opacity: 0;
    }
  }

  .v-list-item--active:hover {
    background-color: var(--primary-25);

    &::before {
      opacity: 0;
    }
  }

  & a.v-list-item--active {
    background-color: var(--primary-10);
    box-shadow: 4px 0 0 0 var(--primary) inset;
    color: var(--primary) !important;
    box-sizing: border-box;

    &::before {
      opacity: 0;
    }
  }
}
</style>
