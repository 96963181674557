<template>
  <div class="layout-messages">
    <transition-group
      name="layout-messages"
      tag="div"
      class="layout-messages__wrapper"
    >
      <v-alert v-for="msg in messages" :key="msg.id" :type="msg.type">
        <div v-if="msg.useHtml == true" v-html="msg.msg"></div>
        <template v-else>{{ msg.msg }}</template>
      </v-alert>
    </transition-group>
  </div>
</template>

<script>
export default {
  computed: {
    messages() {
      return this.$store.state.layoutMessages.messages;
    },
  },
  methods: {
    removeMessage(id) {
      this.$store.commit("layoutMessages/addMessage", id);
    },
  },
};
</script>

<style lang="scss">
.layout-messages {
  position: fixed;
  top: 24px;
  right: 0px;
  z-index: 99999;
  padding: 0 24px;
  width: 500px;
  @media screen and (width: 650px) {
    width: 100%;
  }
  &__wrapper {
    width: 100%;
    position: relative;
  }
}

.layout-messages-enter-active,
.layout-messages-leave-active {
  transition: all 0.3s ease;
}
.layout-messages-enter,
.layout-messages-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(50px);
}
</style>
