import Axios from "../../plugins/axios";

export default {
  namespaced: true,
  state: () => ({
    notifications: [],
  }),
  getters: {},
  mutations: {
    setNotifications(state, data) {
      state.notifications = [];
      state.notifications = [...data];
    },
    removeNotification(state, id) {
      let index = state.notifications.findIndex(
        (i) => i._id.toString() == id.toString()
      );
      if (index != -1) state.notifications.splice(index, 1);
      return;
    },
    addNotification(state, notification) {
      state.notifications.push(notification);
    },
  },
  actions: {
    async addNotification({ commit }, notification) {
      commit("addNotification", notification);
    },
    async fetchNotifications({ commit }) {
      try {
        const response = await Axios.get("/notifications");
        if (response && response.notifications) {
          commit("setNotifications", response.notifications);
        }
      } catch (error) {
        console.error(
          "[STORE/notifications@fetchNotifications] Error fetching notifications: "
        );
        console.error(error);
      }
    },
  },
};
