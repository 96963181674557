import { io } from "socket.io-client";
// eslint-disable-next-line no-unused-vars
import { Socket } from "socket.io-client";
import store from "./store";

/**
 * @type {Map<string, Socket>}
 */
const sockets = new Map();

/**
 * Use socket.io client WS solution
 */
export function useWebSocket() {
  return {
    /**
     * Connect to web socket
     * @param {string} path
     * @param {string} room
     */
    connect(path, room) {
      if (this.isJoined(path)) {
        return this.getSocketByPath(path);
      }

      try {
        const { accessToken, refreshToken } = store.getters["auth/getTokens"];
        const token = `Bearer ${accessToken}#${refreshToken}`;

        const socket = io(process.env.VUE_APP_SOCKETS_BASE_PATH + path, {
          path: "/api/sockets",
          auth: {
            token,
          },
          query: {
            room: room,
          },
          forceNew: true,
        });

        const _socket = socket.connect();
        sockets.set(path, _socket);

        return _socket;
      } catch {
        return undefined;
      }
    },
    /**
     * Get sockets by path
     * @param {string} path
     * @returns {Socket | undefined}
     */
    getSocketByPath(path) {
      return sockets.get(path);
    },
    /**
     * Check if client is connected to socket by their path
     * @param {string} path
     * @returns {boolean}
     */
    isJoined(path) {
      if (sockets.has(path)) {
        const socket = sockets.get(path);
        return socket.connected;
      }

      return false;
    },
    /**
     * Disconnect from WS manually
     * @param {string} path
     * @returns {boolean} true if socket was disconnected, false otherwise
     */
    disconnect(path) {
      if (this.isJoined(path)) {
        /**
         * @type {Socket}
         */
        const socket = this.getSocketByPath(path);
        socket.disconnect();
        return socket.disconnected;
      }

      return false;
    },
  };
}
