import axios from "axios";
import store from "../store";

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_PATH}/api`,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    await store.dispatch("auth/setTokensFromLocalStorage");
    const { accessToken, refreshToken } = store.getters["auth/getTokens"];

    if (accessToken && refreshToken) {
      config.headers["authorization"] = `Bearer ${accessToken}#${refreshToken}`;
    }

    const routesToExcludeForDoppelganger = ["/users/me"];

    let url = config.url;
    if (url.includes("?")) {
      const index = url.indexOf("?");
      url = url.substring(0, index);
    }

    if (
      !routesToExcludeForDoppelganger.includes(url) &&
      !url.includes("admin") &&
      store.getters["doppelganger/isDoppelgangerEnabled"]
    ) {
      config.headers["doppelganger"] =
        store.getters["doppelganger/doppelganger"]._id;
    }

    config.headers["x-lc-view-all"] =
      localStorage.getItem("lc_view_all") || "No";

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    if (
      typeof response.data["__tokens__"] !== "undefined" &&
      typeof response.data["__tokens__"].accessToken == "string" &&
      typeof response.data["__tokens__"].refreshToken == "string"
    ) {
      const accessToken = response.data["__tokens__"].accessToken;
      const refreshToken = response.data["__tokens__"].refreshToken;

      await store.dispatch("auth/setTokensInLocalStorage", {
        accessToken,
        refreshToken,
      });
    }

    return {
      response,
      data: response.data, //TODO: Delete someday
      ...response.data,
    };
  },
  async function (error) {
    if (
      typeof error["response"] !== "undefined" &&
      error.response.status === 401
    ) {
      await store.dispatch("auth/logout");
      await store.dispatch("layoutMessages/addMessage", {
        type: "error",
        msg: "Your session is closed. Please log in again",
      });
      return;
    }

    return Promise.reject({
      ...error,
      msg: error.response.data.msg ? error.response.data.msg : "",
      errorCode: error.response.data.errorCode
        ? error.response.data.errorCode
        : "",
      message: error.response.data.message,
      status: error.response.status,
      url: error.config.url,
    });
  }
);

export default axiosInstance;

export const Axios = {
  install(Vue) {
    Vue.prototype.$http = axiosInstance;
  },
};
