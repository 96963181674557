const DOPPELGANGER_CACHE_KEY = "lc_doppelganger";
import store from "@/store";

export default {
  namespaced: true,
  state: () => ({
    doppelganger: null,
  }),
  getters: {
    /**
     * Check if doppelganger mode is currently enabled
     * @param state
     * @returns {boolean}
     */
    isDoppelgangerEnabled(state) {
      return state.doppelganger !== null;
    },
    /**
     * Get current doppelganger
     * @param state
     * @returns {null}
     */
    doppelganger(state) {
      return state.doppelganger;
    },
  },
  mutations: {
    /**
     * Set doppelganger in store
     * @param state
     * @param doppelganger
     */
    setDoppelganger(state, doppelganger) {
      state.doppelganger = doppelganger;
    },
    /**
     * Clear doppelganger
     * @param state
     */
    clearDoppelganger(state) {
      state.doppelganger = null;
    },
  },
  actions: {
    /**
     * Set doppelganger
     * @param commit
     * @param state
     * @param user
     */
    setDoppelganger({ commit, state }, user) {
      if (typeof user === "undefined" || user === null) return;

      if (state.doppelganger !== null && state.doppelganger._id === user._id) {
        commit("clearDoppelganger");
        localStorage.removeItem(DOPPELGANGER_CACHE_KEY);
        store
          .dispatch("layoutMessages/addMessage", {
            type: "warning",
            msg: "Going back to your own account!",
          })
          .then();
      } else {
        localStorage.setItem(DOPPELGANGER_CACHE_KEY, JSON.stringify(user));
        commit("setDoppelganger", user);

        store
          .dispatch("layoutMessages/addMessage", {
            type: "info",
            msg: `Currently you mimic ${user.firstName + " " + user.lastName}`,
          })
          .then();
      }
    },
    /**
     * Clear doppelganger
     * @param commit
     */
    clearDoppelganger({ commit }) {
      localStorage.removeItem(DOPPELGANGER_CACHE_KEY);
      return commit("clearDoppelganger");
    },
    /**
     * Refresh doppelganger from local storage
     * @param commit
     */
    refreshDoppelganger({ commit }) {
      const stringifiedDoppelganger = localStorage.getItem(
        DOPPELGANGER_CACHE_KEY
      );

      if (stringifiedDoppelganger !== null) {
        try {
          const doppelganger = JSON.parse(stringifiedDoppelganger);
          commit("setDoppelganger", doppelganger);
        } catch {
          console.error(
            "[store/doppelganger@refreshDoppelganger] Error was thrown during parsing doppelganger document from cache. Invalid JSON."
          );
        }
      }
    },
  },
};
