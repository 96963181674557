<template>
  <div>
    <div ref="reference" class="cursor-pointer">
      <template v-if="hover">
        <v-hover v-model="showTooltip">
          <slot name="reference"></slot>
        </v-hover>
      </template>
      <template v-else>
        <span @click="showTooltip = !showTooltip">
          <slot name="reference"></slot>
        </span>
      </template>
    </div>

    <div
      :data-hidden="!showTooltip"
      class="tooltip-content rounded-xl pa-4 pb-6 white outlined-border"
      ref="content"
      :style="contentStyle"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";

export default {
  data() {
    return {
      popper: null,
      showTooltip: false,
    };
  },
  props: {
    position: {
      type: String,
      default: "bottom",
    },
    width: {
      type: String,
      default: "350px",
    },
    hover: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    contentStyle() {
      const o = {};
      o["width"] = this.width;
      return o;
    },
  },
  watch: {
    showTooltip() {
      if (!this.popper) return;
      this.popper.update();
    },
  },
  methods: {
    close() {
      this.showTooltip = false;
    },
    open() {
      this.showTooltip = true;
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.popper = createPopper(
        this.$refs["reference"],
        this.$refs["content"],
        {
          placement: this.position,
        }
      );
    });
  },
};
</script>
<style lang="scss" scoped>
.tooltip-content {
  position: relative;
  z-index: 999999 !important;
  transition: opacity 0.1s ease;
  overflow: hidden;

  &[data-hidden="true"] {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
  }
}
</style>
