export default {
  namespaced: true,
  state: () => ({
    messages: [],
  }),
  getters: {},
  mutations: {
    addMessage(state, data) {
      state.messages.push({
        type: data.type,
        msg: data.msg,
        useHtml: data.useHtml || false,
        id: data.id,
      });
    },
    removeMessage(state, id) {
      let index = state.messages.findIndex((i) => i.id == id);
      if (index != -1) state.messages.splice(index, 1);
    },
  },
  actions: {
    addMessage({ commit }, data) {
      const id =
        "message_" + Date.now() + "_" + Math.floor(Math.random() * 10000);

      commit("addMessage", {
        ...data,
        id,
      });

      if (!data.duration || data.duration != -1) {
        let dur = 10000;
        if (data.duration) dur = data.duration;

        setTimeout(() => {
          commit("removeMessage", id);
        }, dur);
      }
    },
  },
};
