import Vue from "vue";
import Vuex from "vuex";
import layoutMessages from "./modules/layout-messages";
import dashboardNotifications from "./modules/dashboard-notifications";
import userSurvey from "./modules/user-survey";
import auth from "./modules/auth";
import Axios from "../plugins/axios";
import doppelganger from "@/store/modules/doppelganger";
import { DateTime } from "luxon";
import { fetchUnseenConversationsCount } from "@/api/conversations.api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dashboardTitle: "",
    unseenInboxMessages: 0,
    betaFeaturesAccesses: [],
    showLoader: false,
    showSupport: false,
    showUserSurvey: false,
    showNavigation: false,
    currentWidth: 0,
    display: {
      isExtraSmall: false,
      isSmall: false,
      isMedium: false,
      isLarge: false,
      isExtraLarge: false,
    },
  },
  getters: {
    unseenInboxMessages(state) {
      return state.unseenInboxMessages;
    },
    betaFeaturesAccesses(state) {
      return state.betaFeaturesAccesses;
    },
    showLoader(state) {
      return state.showLoader;
    },
    showUserSurvey(state) {
      return state.showUserSurvey;
    },
    isDisplayExtraSmall(state) {
      return state.display.isExtraSmall;
    },
    isDisplaySmall(state) {
      return state.display.isSmall;
    },
    isDisplayMedium(state) {
      return state.display.isMedium;
    },
    isDisplayLarge(state) {
      return state.display.isLarge;
    },
    isDisplayExtraLarge(state) {
      return state.display.isExtraLarge;
    },
  },
  mutations: {
    setShowUserSurvey(state, value) {
      state.showUserSurvey = value;
    },
    setShowSupport(state, value) {
      state.showSupport = value;
    },
    setDashboardTitle(state, s) {
      state.dashboardTitle = s;
    },
    setUnseenInboxMessagesCount(state, c) {
      state.unseenInboxMessages = c;
    },
    setDisplayObject(state, object) {
      state.display = object;
    },
    setBetaFeaturesAccesses(state, c) {
      state.betaFeaturesAccesses = c;
    },
    modifyInboxUnreadMessagesCount(state, inc) {
      if (state.unseenInboxMessages + inc >= 0) {
        state.unseenInboxMessages = state.unseenInboxMessages + inc;
      }
    },
    changeLoaderState(state, value) {
      state.showLoader = value;
    },
  },
  actions: {
    /**
     * Toggle state of the loader
     */
    toggleLoader({ commit }, value) {
      commit("changeLoaderState", value);
    },
    /**
     * Set 'showUserSurvey' variable to defined state
     */
    setShowUserSurvey({ commit }, value) {
      commit("setShowUserSurvey", value);
    },
    /**
     * Set 'showSupport' variable to defined state
     */
    setShowSupport({ commit }, value) {
      commit("setShowSupport", value);
    },
    /**
     * Set 'display' object with values
     * @param
     */
    setDisplayObject({ commit }, object) {
      commit("setDisplayObject", object);
    },
    /**
     * Fetch information about the beta accesses for the user
     */
    async fetchBetaFeaturesAccesses({ commit }) {
      const response = await Axios.get("/users/me/beta-accesses");

      if (response && response["accesses"]) {
        commit("setBetaFeaturesAccesses", response["accesses"]);
      }
    },
    /**
     * Fetch information about unseen conversations and store this information in store
     */
    async fetchUnseenRepliesCount({ commit }) {
      const count = await fetchUnseenConversationsCount();

      if (typeof count === "undefined") return;

      commit("setUnseenInboxMessagesCount", count);

      const incomingMessagesMonit = localStorage.getItem(
        "lc_incoming_messages_monit"
      );

      const now = DateTime.now().toMillis();
      if (incomingMessagesMonit === null && count > 0) {
        await this.dispatch("layoutMessages/addMessage", {
          type: "info",
          msg: `You have ${count} messages in your inbox. Don't let them wait!`,
        });

        localStorage.setItem("lc_incoming_messages_monit", now.toString());
      } else {
        const parsedValue = JSON.parse(incomingMessagesMonit);
        const fourHoursInMs = 1000 * 60 * 60 * 4;

        if (parsedValue + fourHoursInMs < now) {
          localStorage.removeItem("lc_incoming_messages_monit");
        }
      }
    },
  },
  modules: {
    auth,
    layoutMessages,
    dashboardNotifications,
    userSurvey,
    doppelganger,
  },
});
