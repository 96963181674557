// noinspection CommaExpressionJS

export const REGEX_EMAIL_ADDRESS =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_STRONG_PASSWORD = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,100})"
);
const urlRegex =
  "^(?!mailto:)(?:(?:http|https|ftp)://)?(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
export const REGEX_URL = new RegExp(urlRegex, "i");

export const REGEX_STRONG_PASSWORD_PARTIALS = {
  upperCase: /^(?=.*[A-Z]).{0,}$/,
  lowerCase: /^(?=.*[a-z]).{0,}$/,
  specialCharacter: /^(?=.*[!@#$&*]).{0,}$/,
  number: /^(?=.*[0-9]).{0,}$/,
  length: /^.{8,100}$/,
};

export const RULE_EMAIL_ADDRESS = (value) => {
  return REGEX_EMAIL_ADDRESS.test(value) || "Provided email is invalid.";
};

export const RULE_FIELD_REQUIRED = (value) =>
  !!value || "This field is required.";

export const RULE_FIELD_NOT_EMPTY = (value) =>
  (typeof value === "string" && value.length > 0) ||
  "This field cannot be empty";

export const RULE_STRONG_PASSWORD = (value) => {
  if (typeof value === "string" && value.length >= 100) {
    return "Your password is to long";
  }

  return REGEX_STRONG_PASSWORD.test(value) || "Your password is to weak";
};

export const RULE_URL = (value) => {
  return (
    value === "" ||
    REGEX_URL.test(value) ||
    "Your field needs to be correct url address"
  );
};

export const RULE_MIN_MEETINGS = (value) =>
  value >= 20 || "Minimum amount of meetings per month is 20.";

export const CONTACT_STATUSES = [
  {
    value: "NEW",
    color: "#e69946",
    textColor: "#593103",
    text: "New Lead",
  },
  {
    value: "REPLIED",
    color: "#c495df",
    textColor: "#291139",
    text: "Neutral Response",
  },
  {
    value: "WANTS_MEETING",
    color: "#E0AED0",
    textColor: "#391f32",
    text: "Qualified Lead",
  },
  {
    value: "LATER",
    color: "#51829B",
    textColor: "#ceedff",
    text: "Later",
  },
  {
    value: "WAITING",
    color: "#FFD6A5",
    textColor: "#573c1b",
    text: "Waiting",
  },
  {
    value: "NO_INTEREST",
    color: "#D37676",
    textColor: "#420b0b",
    text: "Not Interested",
  },
  {
    value: "MANUAL_ACTION",
    color: "#D37676",
    textColor: "#420b0b",
    text: "Manual Review",
  },
  {
    value: "MEETING_BOOKED",
    color: "#75A47F",
    textColor: "#0a2b0d",
    text: "Meeting Booked",
  },
  {
    value: "CUSTOMER",
    color: "#51829B",
    textColor: "#ceedff",
    text: "Customer (Deal Closed)",
  },
  {
    value: "EXISTING_CUSTOMER",
    color: "#51829B",
    textColor: "#ceedff",
    text: "Existing Customer",
  },
  {
    value: "REFERRED",
    color: "#558551",
    textColor: "#bee1bc",
    text: "Colleague Referral",
  },
  {
    value: "EXCLUDED",
    color: "#23201c",
    textColor: "#F3EEEA",
    text: "Excluded",
  },
];

export const USER_SURVEY_COMPANY_SIZE_TYPES = [
  "1-10",
  "11-50",
  "51-100",
  "101-500",
  "501-1000",
  "1001-5000",
  "5001+",
];

export const USER_SURVEY_DESIRED_MEETINGS_TYPES = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15+",
];

export const USER_SURVEY_PERSONALITY_TYPES = [
  "Architect (INTJ-A/INTJ-T)",
  "Logician (INTP-A/INTP-T)",
  "Commander (ENTJ-A/ENTJ-T)",
  "Debater (ENTP-A/ENTP-T)",
  "Advocate (INFJ-A/INFJ-T)",
  "Mediator (INFP-A/INFP-T)",
  "Protagonist (ENFJ-A/ENFJ-T)",
  "Campaigner (ENFP-A/ENFP-T)",
  "Logistician (ISTJ-A/ISTJ-T)",
  "Defender (ISFJ-A/ISFJ-T)",
  "Executive (ESTJ-A/ESTJ-T)",
  "Consul (ESFJ-A/ESFJ-T)",
  "Virtuoso (ISTP-A/ISTP-T)",
  "Adventurer (ISFP-A/ISFP-T)",
  "Entrepreneur (ESTP-A/ESTP-T)",
  "Entertainer (ESFP-A/ESFP-T)",
];

export const USER_LOGIN_STATUS = [
  { text: "Active", value: "ACTIVE" },
  { text: "New", value: "NEW" },
  { text: "Password reset", value: "PASSWORD_RESET" },
];

export const USER_FUNCTIONS = [
  {
    text: "Customer Success",
    value: "CUSTOMER_SUCCESS",
  },
  {
    text: "IT Department",
    value: "DEV",
  },
  {
    text: "Management",
    value: "MANAGEMENT",
  },
  {
    text: "Sales",
    value: "SALES_REP",
  },
];

export const USER_ROLES = [
  {
    text: "Super Admin",
    value: "SUPER_ADMIN",
  },
  {
    text: "Admin",
    value: "ADMIN",
  },
  {
    text: "Only Owner",
    value: "OWNER_ONLY",
  },
  {
    text: "Owner",
    value: "OWNER",
  },
  {
    text: "Regular User",
    value: "USER",
  },
];

export const POSSIBLE_MESSAGE_REPLACEMENTS = [
  { value: "{FirstName}", text: "First Name" },
  { value: "{LastName}", text: "Last Name" },
  { value: "{Company}", text: "Company" },
  { value: "{Title}", text: "Title" },
  { value: "{YourName}", text: "Your Own First Name" },
  { value: "{YourCompany}", text: "Your Own Company Name" },
  { value: "{MeetingBookingLink}", text: "Your Meeting Link" },
];

export const POSSIBLE_MESSAGE_REPLACEMENTS_CONNECTION_MESSAGE = [
  { value: "{FirstName}", text: "First Name" },
  { value: "{Company}", text: "Company" },
  { value: "{Title}", text: "Title" },
  { value: "{YourName}", text: "Your Own First Name" },
  { value: "{YourCompany}", text: "Your Own Company Name" },
];

export const LINKEDIN_ACCOUNT_STATUS = [
  { text: "New", value: "NEW" },
  { text: "Active", value: "ACTIVE" },
  { text: "Error", value: "ERROR" },
  { text: "Setting up", value: "SETTING_UP" },
  { text: "Unknown error", value: "UNKNOWN_ERROR" },
];

export const CONTACT_EXPORT_DEFAULT_TEMPLATE = [
  {
    _id: "1",
    name: "Unverified Email",
    type: "system",
    content: "email",
  },
  {
    _id: "2",
    name: "Verified Email",
    type: "system",
    content: "verifiedEmail",
  },
  {
    _id: "3",
    name: "First name",
    type: "system",
    content: "firstName",
  },
  {
    _id: "4",
    name: "Last name",
    type: "system",
    content: "lastName",
  },
  {
    _id: "5",
    name: "Company name",
    type: "system",
    content: "company",
  },
  {
    _id: "6",
    name: "Phone number",
    type: "system",
    content: "phone",
  },
  {
    _id: "7",
    name: "Title",
    type: "system",
    content: "title",
  },
  {
    _id: "8",
    name: "Linkedin profile",
    type: "system",
    content: "linkedinProfile",
  },
];
