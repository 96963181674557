<template>
  <v-expand-transition>
    <div class="dashboard-announcement" v-if="!loading && showAnnouncement">
      <div class="dashboard-announcement__close">
        <v-btn color="white" @click="close" x-small depressed fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-row align="center">
        <v-col class="grow">
          <h3 class="dashboard-announcement__title">
            {{ announcement["title"] }}
          </h3>
          <p
            class="dashboard-announcement__lead"
            v-html="announcement['lead']"
          />
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="white"
            outlined
            @click="goToAnnouncement"
            depressed
            large
          >
            Read more
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-expand-transition>
</template>
<style lang="scss" scoped>
.dashboard-announcement {
  width: 100%;
  padding: 30px 45px;
  color: white;
  background: var(--secondary);
  position: relative;

  &__title {
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__lead {
    font-size: 16px;
    line-height: 24px;
    color: white;
    margin-top: 10px;
    margin-bottom: 0;
  }

  &__close {
    position: absolute;
    top: 9px;
    right: 8px;
  }
}
</style>
<script>
export default {
  data() {
    return {
      loading: true,
      showActionDialog: false,
      announcement: null,
    };
  },
  methods: {
    goToAnnouncement() {
      this.close();
      this.$router.push(`/announcements/${this.announcement.path}`);
    },
    async fetchData() {
      this.loading = true;
      try {
        const response = await this.$http.get("/dashboard/announcements");
        if (
          response.data.announcement !== null &&
          typeof response.data.announcement !== "undefined"
        ) {
          this.announcement = response.data.announcement;
        }
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    close() {
      const announcementClosed = localStorage.getItem("lc_announcements_close");

      if (announcementClosed !== null) {
        const parsedAnnouncementClosed = JSON.parse(announcementClosed);

        if (!parsedAnnouncementClosed.includes(this.announcement._id)) {
          parsedAnnouncementClosed.push(this.announcement._id);

          localStorage.setItem(
            "lc_announcements_close",
            JSON.stringify(parsedAnnouncementClosed)
          );
        }
      } else {
        localStorage.setItem(
          "lc_announcements_close",
          JSON.stringify([this.announcement._id])
        );
      }

      this.announcement.active = false;
    },
    propertyExists(name) {
      if (!this.announcement[name]) return false;
      if (this.announcement[name].length === 0) return false;
      return true;
    },
    makeClassObjectFromArray(arr) {
      const o = {};
      for (let i = 0; i < arr.length; i++) {
        o[arr[i]] = true;
      }
      return o;
    },
  },
  async mounted() {
    await this.fetchData();
  },
  computed: {
    showAnnouncement() {
      if (
        !this.announcement ||
        !this.announcement.active ||
        this.$route.name === "admin-dashboard" ||
        this.$route.name === "announcements" ||
        this.$route.name === "announcement"
      ) {
        return false;
      }

      const announcementClosed = localStorage.getItem("lc_announcements_close");

      if (announcementClosed !== null) {
        const parsedAnnouncementClosed = JSON.parse(announcementClosed);

        if (parsedAnnouncementClosed.includes(this.announcement._id)) {
          return false;
        }
      }

      return true;
    },
  },
};
</script>
