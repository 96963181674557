import Vue from "vue";
import store from "../store";

export const LayoutMessages = {
  install(Vue) {
    Vue.prototype.$message = (obj) => {
      store.dispatch("layoutMessages/addMessage", obj);
    };
  },
};
