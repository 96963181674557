<template>
  <v-dialog
    v-model="$store.state.userSurvey.showDialog"
    persistent
    scrollable
    content-class="rounded-xl"
    max-width="1024px"
  >
    <v-card class="px-4 py-4">
      <v-card-title>
        <div
          style="width: 100%"
          class="d-flex justify-space-between align-center"
        >
          <AdvancedStepper
            :label="stepperLabel"
            :value="stepperValue"
            :textInside="stepperTextInside"
          />

          <v-btn fab small text color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-expand-transition v-for="step in steps" :key="step.step">
          <v-form v-model="valid" ref="form" v-if="currentStep === step.step">
            <p class="pt-4" v-html="step.description" />
            <v-divider v-if="step.questions" />
            <template v-if="step.questions">
              <template v-for="(question, index) in step.questions">
                <span
                  class="d-block mb-2 mt-4 text-body-2"
                  :key="index"
                  v-html="question.question"
                />
                <v-textarea
                  v-if="question.type === 'textarea'"
                  :rows="currentStep === 2 ? 1 : 3"
                  :key="question.key"
                  :loading="loading"
                  :disabled="loading"
                  :rules="
                    question.required
                      ? [rules.required, rules.maxSurveyLength]
                      : [rules.maxSurveyLength]
                  "
                  outlined
                  :counter="2000"
                  persistent-hint
                  :hint="question.hint"
                  v-model="$store.state.userSurvey.survey[question.key]"
                />
                <v-select
                  v-if="question.type === 'select'"
                  :key="question.key"
                  :loading="loading"
                  :disabled="loading"
                  :items="question.answers"
                  clearable
                  outlined
                  persistent-hint
                  :hint="question.hint"
                  v-model="$store.state.userSurvey.survey[question.key]"
                  :rules="question.required ? [rules.required] : []"
                />
              </template>
            </template>
          </v-form>
        </v-expand-transition>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          small
          :loading="loading"
          @click="handlePrevAction"
          color="primary"
        >
          {{ prevButtonText }}
        </v-btn>
        <v-spacer />
        <v-fade-transition>
          <v-btn
            v-if="currentStep > 1 && currentStep !== steps.length"
            outlined
            small
            color="primary"
            :disabled="!valid"
            :loading="loading"
            @click="updateSurvey"
          >
            Save survey & Exit
          </v-btn>
        </v-fade-transition>

        <v-btn
          depressed
          color="primary"
          small
          :disabled="!valid"
          :loading="loading"
          @click="handleNextAction"
        >
          {{ nextButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AdvancedStepper from "@/components/AdvancedStepper";
import { SURVEY_STEPS } from "./user-survey-steps";
import { RULE_FIELD_REQUIRED } from "@/const";

const CHAR_LIMIT = 2000;

export default {
  components: {
    AdvancedStepper,
  },
  data() {
    return {
      survey_dialog_closed_key: "lc_user_survey_dialog_closed",
      valid: false,
      currentStep: 1,
      steps: SURVEY_STEPS,
      rules: {
        maxSurveyLength: (value) => {
          return (
            typeof value === "undefined" ||
            (typeof value === "string" && value.length <= CHAR_LIMIT) ||
            `This field cannot be longer than ${CHAR_LIMIT} characters`
          );
        },
        required: RULE_FIELD_REQUIRED,
      },
    };
  },
  props: {
    forceHidden: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Loading state
     */
    loading() {
      return this.$store.getters["userSurvey/loading"];
    },
    /**
     * Prev button text
     */
    prevButtonText() {
      return this.steps[this.currentStep - 1].buttons.prev;
    },
    /**
     * Next button text
     */
    nextButtonText() {
      return this.steps[this.currentStep - 1].buttons.next;
    },
    /**
     * Stepper label
     */
    stepperLabel() {
      return this.steps[this.currentStep - 1].title.replace(
        "{{USERNAME}}",
        this.userFirstName
      );
    },
    /**
     * Stepper value
     */
    stepperValue() {
      return Math.ceil((this.currentStep / this.steps.length) * 100);
    },
    /**
     * Stepper text inside
     */
    stepperTextInside() {
      return `${this.currentStep}/${this.steps.length}`;
    },
    /**
     * User first name
     */
    userFirstName() {
      const user = this.$store.getters["auth/getUser"];
      return typeof user !== "undefined" &&
        user !== null &&
        typeof user.firstName === "string"
        ? user.firstName
        : "";
    },
    /**
     * Completion level
     */
    completion() {
      return this.$store.getters["userSurvey/completion"];
    },
    /**
     * Is survey completed
     */
    isCompleted() {
      return this.completion === 100;
    },
  },
  methods: {
    /**
     * Handle prev action
     */
    handlePrevAction() {
      if (this.loading) return;

      if (this.currentStep === 1) {
        this.close();
      } else {
        this.currentStep--;
      }
    },
    /**
     * Handle next action
     */
    handleNextAction() {
      if (this.loading) return;

      if (this.currentStep === this.steps.length) {
        this.updateSurvey();
      } else {
        this.currentStep++;
      }
    },
    /**
     * Should dialog be opened
     */
    shouldDialogBeOpened() {
      const value = localStorage.getItem(this.survey_dialog_closed_key);
      const user = this.$store.getters["auth/getUser"];

      return (
        value !== "true" && this.completion < 100 && user.role !== "SUPER_ADMIN"
      );
    },

    /**
     * Close dialog
     */
    close() {
      this.$store.dispatch("userSurvey/setShowDialog", false);
      this.$store.dispatch("userSurvey/setLoading", false);
      localStorage.setItem(this.survey_dialog_closed_key, "true");
      this.$emit("close");

      this.currentStep = 1;
    },
    /**
     * Perform update on a survey
     */
    async updateSurvey() {
      if (!this.loading) {
        await this.$store.dispatch("userSurvey/updateSurvey");
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("userSurvey/loadSurvey");

    // TODO: Check if this is correct place to unforce dialog from showing on signup
    // if (!this.forceHidden && this.shouldDialogBeOpened()) {
    //   this.$store.dispatch("userSurvey/setShowDialog", true);
    // }
  },
};
</script>
