import axios from "@/plugins/axios";

/**
 * Change conversation archive status to defined one
 * @param {string} id
 * @param {boolean} archived
 * @returns {Promise<boolean|undefined>}
 */
export async function changeConversationArchiveStatus(id, archived) {
  try {
    const response = await axios.put(`/conversations/${id}/archive`, {
      archived,
    });

    if (
      response["success"] &&
      typeof response["conversation"]["archived"] === "boolean"
    ) {
      return response["conversation"]["archived"];
    }

    return undefined;
  } catch (error) {
    console.error(
      `[inbox@changeConversationArchiveStatus] We failed to change the archive status of the conversation with id: ${id}. Error thrown`,
      error
    );

    return undefined;
  }
}

/**
 * Change conversation pinned status to defined one
 * @param {string} id
 * @param {boolean} favourite
 * @returns {Promise<boolean|undefined>}
 */
export async function changeConversationFavouriteStatus(id, favourite) {
  try {
    const response = await axios.put(`/conversations/${id}/favourite`, {
      favourite,
    });

    if (
      response["success"] &&
      typeof response["conversation"]["favourite"] === "boolean"
    ) {
      return response["conversation"]["favourite"];
    }

    return undefined;
  } catch (error) {
    console.error(
      `[inbox@changeConversationFavouriteStatus] We failed to change the favourite status of the conversation with id: ${id}. Error thrown`,
      error
    );

    return undefined;
  }
}

/**
 * Change conversation seen status
 * @param id
 * @param seen
 * @returns {Promise<boolean | undefined>}
 */
export async function changeConversationSeenStatus(id, seen) {
  try {
    const response = await axios.post(`/conversations/${id}/seen`, { seen });

    if (
      response["success"] &&
      typeof response["conversation"]["seen"] === "boolean"
    ) {
      return response["conversation"]["seen"];
    }

    return undefined;
  } catch (error) {
    console.error(
      `[inbox@changeConversationSeenStatus] We failed to change seen status of the conversation with id: ${id}. Error thrown`,
      error
    );

    return undefined;
  }
}

/**
 * Returns conversations with pagination attached to a user
 * @param {{page: number, itemsPerPage: number}} pagination
 * @param {string?} query
 * @returns {Promise<{pagination: {page: number, itemsPerPage: number, numberOfPages: number, numberOfItems: number}, conversations: Object[]} | undefined>}
 */
export async function getConversations(pagination, query) {
  try {
    const params = new URLSearchParams(query);

    params.append("page", pagination.page.toString());
    params.append("itemsPerPage", pagination.itemsPerPage.toString());

    const response = await axios.get(`/conversations?${params.toString()}`);

    if (response["success"] && Array.isArray(response["conversations"])) {
      return {
        conversations: response["conversations"],
        pagination: response["pagination"],
      };
    }
  } catch (error) {
    console.error(
      "[inbox@getConversations] We tried to fetch conversation for the inbox, however error was thrown",
      error
    );
    return undefined;
  }
}

/**
 * Fetch conversation document from API
 * @param {string} id
 * @returns {Promise<Object | undefined>}
 */
export async function getConversationById(id) {
  try {
    const response = await axios.get(`/conversations/${id}`);

    if (response["success"]) {
      const conversation = response["conversation"];
      conversation["messages"] = assignPriorityToMessages(response["messages"]);

      return conversation;
    }

    return undefined;
  } catch (error) {
    console.error(
      `[inbox@getConversationById] We failed to fetch conversation with id: ${id}. Error thrown`,
      error
    );
    return undefined;
  }
}

/**
 * Does user have any conversations
 * @returns {Promise<boolean>}
 */
export async function doesUserHaveAnyConversations() {
  try {
    const response = await this.$http.get(`/conversations/have-any`);

    if (response["success"]) {
      return response["haveConversations"];
    }

    return false;
  } catch (error) {
    console.error(
      `[inbox@doesUserHaveAnyConversations] During fetching information about if user have any conversation error was thrown`,
      error
    );
    return false;
  }
}

/**
 * Fetch information about how many conversations are set as unseen
 * @returns {Promise<number|undefined>}
 */
export async function fetchUnseenConversationsCount() {
  try {
    const response = await axios.get("/conversations/count-unseen");

    if (response && typeof response["count"] === "number") {
      return response["count"];
    }

    return undefined;
  } catch (error) {
    console.error(
      `[inbox@fetchUnseenConversationsCount] During fetching information about how many conversations are unseen error was thrown`,
      error
    );

    return undefined;
  }
}

/**
 * Assign priority to messages
 */
export function assignPriorityToMessages(messages) {
  let priority = 1;
  let prev = undefined;
  const _messages = [];

  for (const message of messages) {
    if (typeof prev !== "undefined") {
      if (
        prev.campaign &&
        message.campaign &&
        prev.campaign._id === message.campaign._id &&
        prev.source === message.source &&
        !message.isReply
      ) {
        priority += 1;
      } else {
        priority = 1;
      }
    }

    prev = message;
    message.priority = priority;
    _messages.push(message);
  }

  return _messages;
}
