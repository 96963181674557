<template>
  <div class="signup-layout">
    <LayoutMessages />
    <v-main class="signup-layout-main py-8">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6>
            <v-spacer></v-spacer>
            <img src="@/assets/img/lc-logo-full.png" class="logo" />
            <v-spacer></v-spacer>
            <v-card rounded class="rounded-xl" flat>
              <slot />
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import LayoutMessages from "@/components/LayoutMessages";
export default {
  name: "SignupLayout",
  components: { LayoutMessages },
};
</script>
<style lang="scss">
.signup-layout {
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.logo {
  display: block;
  margin: 0 auto 40px auto;
  width: 200px;
  height: auto;
}
</style>
