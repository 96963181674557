import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import DashboardLayout from "@/layouts/DashboardLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import SignupLayout from "@/layouts/SignupLayout.vue";
import InitialWizardLayout from "@/layouts/InitialWizardLayout.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/first-steps",
      name: "first-steps",
      component: () => import("@/views/InitialWizardIndex.vue"),
      meta: {
        documentTitle: "First Steps | LinkedClient",
        layout: InitialWizardLayout,
        requiresAuth: true,
      },
    },
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
      meta: {
        documentTitle: "Elsie | LinkedClient",
        layout: DashboardLayout,
        requiresAuth: true,
        disablePadding: true,
        disableScroll: true,
      },
    },
    {
      path: "/insights",
      name: "insights",
      component: () => import("@/views/Insights.vue"),
      meta: {
        documentTitle: "Elsie Insight Hub | LinkedClient",
        layout: DashboardLayout,
        requiresAuth: true,
      },
    },
    {
      path: "/announcements",
      name: "announcements",
      component: () => import("@/views/Announcements.vue"),
      meta: {
        documentTitle: "Announcements | LinkedClient",
        pageTitle: "Announcements ✨",
        layout: DashboardLayout,
        requiresAuth: true,
      },
    },
    {
      path: "/announcements/:path",
      name: "announcement",
      component: () => import("@/views/Announcement.vue"),
      meta: {
        documentTitle: "Announcements | LinkedClient",
        layout: DashboardLayout,
        requiresAuth: true,
      },
    },
    {
      path: "/tutorials",
      name: "tutorials",
      component: () => import("@/views/VideoTutorials.vue"),
      meta: {
        documentTitle: "Video Tutorials | LinkedClient",
        pageTitle: "Video Tutorials 🎬",
        layout: DashboardLayout,
        requiresAuth: true,
      },
    },
    {
      path: "/admin/dashboard",
      name: "admin-dashboard",
      component: () => import("@/views/Admin/AdminIndex.vue"),
      meta: {
        layout: DashboardLayout,
        documentTitle: "Dashboard | Admin LinkedClient",
        pageTitle: "🔐 Admin Dashboard",
        requiresAuth: true,
        isSuperAdmin: true,
      },
    },
    {
      path: "/admin/users",
      name: "admin-users",
      component: () => import("@/views/Admin/AdminUsers.vue"),
      meta: {
        documentTitle: "Users | Admin LinkedClient",
        layout: DashboardLayout,
        pageTitle: "🥷 Users management",
        requiresAuth: true,
        isSuperAdmin: true,
      },
    },
    {
      path: "/admin/users/:id",
      name: "admin-user",
      component: () => import("@/views/Admin/AdminUser.vue"),
      meta: {
        documentTitle: "Users | Admin LinkedClient",
        layout: DashboardLayout,
        pageTitle: "🥷 Users management",
        requiresAuth: true,
        isSuperAdmin: true,
        disableWhiteBackground: true,
      },
    },
    {
      path: "/admin/organizations",
      name: "admin-organizations",
      component: () => import("@/views/Admin/AdminOrganizations.vue"),
      meta: {
        documentTitle: "Organizations | Admin LinkedClient",
        layout: DashboardLayout,
        pageTitle: "🏢 Organizations management",
        requiresAuth: true,
        isSuperAdmin: true,
      },
    },
    {
      path: "/admin/linkedin",
      name: "admin-linkedin",
      component: () => import("@/views/Admin/AdminLinkedinAccounts.vue"),
      meta: {
        documentTitle: "LinkedIn Accounts | Admin LinkedClient",
        layout: DashboardLayout,
        pageTitle: "🔗 LinkedIn Accounts management",
        requiresAuth: true,
        isSuperAdmin: true,
      },
    },
    {
      path: "/admin/announcements",
      name: "admin-announcements",
      component: () => import("@/views/Admin/AdminAnnouncements.vue"),
      meta: {
        documentTitle: "Announcements | Admin LinkedClient",
        layout: DashboardLayout,
        pageTitle: "📣 Announcements",
        requiresAuth: true,
        isSuperAdmin: true,
      },
    },
    {
      path: "/admin/announcements/:id",
      name: "admin-announcement",
      component: () => import("@/views/Admin/AdminAnnouncement.vue"),
      meta: {
        documentTitle: "Announcements Editor | Admin LinkedClient",
        layout: DashboardLayout,
        pageTitle: "📣 Announcement editor",
        requiresAuth: true,
        isSuperAdmin: true,
      },
    },
    {
      path: "/admin/sales-reps",
      name: "admin-sales-reps",
      component: () => import("@/views/Admin/AdminSalesReps.vue"),
      meta: {
        documentTitle: "Sales Reps | Admin LinkedClient",
        layout: DashboardLayout,
        pageTitle: "💸 Sales Reps management",
        requiresAuth: true,
        isSuperAdmin: true,
      },
    },
    {
      path: "/admin/onboardings",
      name: "admin-onboardings",
      component: () => import("@/views/Admin/AdminOnboardings.vue"),
      meta: {
        documentTitle: "Onboardings | Admin LinkedClient",
        layout: DashboardLayout,
        pageTitle: "🛟 Onboardings",
        requiresAuth: true,
        isSuperAdmin: true,
      },
    },
    {
      path: "/admin/custom-signup",
      name: "admin-custom-signup",
      component: () => import("@/views/Admin/AdminCustomSignups.vue"),
      meta: {
        documentTitle: "Custom Signups | Admin LinkedClient",
        layout: DashboardLayout,
        pageTitle: " Custom signups",
        requiresAuth: true,
        isSuperAdmin: true,
      },
    },
    {
      path: "/inbox",
      name: "inbox",
      component: () => import("@/views/Inbox.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        documentTitle: "Inbox | LinkedClient",
        pageTitle: "Inbox 📨",
        disablePadding: true,
        disableScroll: true,
      },
    },
    {
      path: "/inbox/:id",
      name: "inbox_selected",
      component: () => import("@/views/Inbox.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        documentTitle: "Inbox | LinkedClient",
        pageTitle: "Inbox 📨",
        disablePadding: true,
        disableScroll: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      props: true,
      meta: {
        layout: AuthLayout,
        guest: true,
        documentTitle: "Login | LinkedClient",
      },
    },
    {
      path: "/resetpassword",
      name: "resetpassword",
      component: () => import("@/views/ResetPassword.vue"),
      meta: {
        layout: AuthLayout,
        guest: true,
        documentTitle: "Reset Password | LinkedClient",
      },
    },
    {
      path: "/signup",
      name: "signup",
      component: () => import("@/views/Signup.vue"),
      meta: {
        layout: SignupLayout,
        guest: true,
        documentTitle: "Welcome to LinkedClient!",
      },
    },
    {
      path: "/signup/:id",
      name: "signupcustom",
      component: () => import("@/views/Signup.vue"),
      meta: {
        layout: SignupLayout,
        guest: true,
        documentTitle: "Welcome to LinkedClient!",
      },
    },
    {
      path: "/contacts",
      name: "contacts",
      component: () => import("@/views/Contacts.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        pageTitle: "Contacts 🏄‍♀️",
        disablePadding: true,
        disableScroll: true,
        documentTitle: "Contacts | LinkedClient",
      },
    },
    {
      path: "/contacts/pipeline",
      name: "contacts",
      component: () => import("@/views/ContactsPipeline.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        pageTitle: "Contacts Pipeline 🧑‍🔧",
        disablePadding: true,
        disableScroll: true,
        documentTitle: "Contacts Pipeline | LinkedClient",
      },
    },
    {
      path: "/contacts/:id",
      name: "contact",
      component: () => import("@/views/Contact.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        documentTitle: "Contacts | LinkedClient",
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/Users.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        pageTitle: "Users",
        isAdmin: true,
        documentTitle: "Users | LinkedClient",
      },
    },
    {
      path: "/users/:id",
      name: "user",
      component: () => import("@/views/User.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        documentTitle: "Users | LinkedClient",
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/User.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        documentTitle: "Profile | LinkedClient",
      },
    },
    {
      path: "/integrations",
      name: "integrations",
      component: () => import("@/views/Integrations.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        documentTitle: "Integrations | LinkedClient",
      },
    },
    {
      path: "/credits",
      name: "credits",
      component: () => import("@/views/Credits.vue"),
      meta: {
        layout: AuthLayout,
        requiresAuth: true,
        pageTitle: "Campaign Email Credits",
        documentTitle: "Credits | LinkedClient",
      },
    },
    {
      path: "/audiences",
      name: "audiences",
      component: () => import("@/views/Audiences.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        pageTitle: "Audiences",
        isSuperAdmin: true,
        documentTitle: "Audiences | LinkedClient",
      },
    },
    {
      path: "/campaigns",
      name: "campaigns",
      component: () => import("@/views/Campaigns.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        disablePadding: true,
        disableScroll: true,
        pageTitle: "Campaigns 🤸🏽",
        documentTitle: "Campaigns | LinkedClient",
      },
    },
    {
      path: "/campaigns/:id",
      name: "campaign",
      component: () => import("@/views/Campaign.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        documentTitle: "Campaigns | LinkedClient",
      },
    },
    {
      path: "/searches",
      name: "searches",
      component: () => import("@/views/Searches.vue"),
      meta: {
        layout: DashboardLayout,
        requiresAuth: true,
        isSuperAdmin: true,
        pageTitle: "Searches",
        documentTitle: "Searches | LinkedClient",
      },
    },
    {
      path: "/emails",
      name: "senders",
      component: () => import("@/views/Senders.vue"),
      meta: {
        layout: DashboardLayout,
        pageTitle: "Email Accounts",
        documentTitle: "Email Accounts | LinkedClient",
        requiresAuth: true,
      },
    },
    {
      path: "/linkedin/accounts",
      name: "accounts",
      component: () => import("@/views/LinkedInAccounts.vue"),
      meta: {
        layout: DashboardLayout,
        documentTitle: "LinkedIn Accounts | LinkedClient",
        pageTitle: "Linkedin Accounts",
        requiresAuth: true,
      },
    },
    {
      path: "/support/linkedin/update-password",
      name: "supportLinkedinUpdatePassword",
      component: () => import("@/views/SupportLinkedin.vue"),
      meta: {
        layout: DashboardLayout,
        documentTitle: "Update LinkedIn Credentials | LinkedClient",
        pageTitle: "Update Linkedin Credentials",
        requiresAuth: true,
      },
    },
    {
      path: "/account-locked",
      name: "lockScreen",
      component: () => import("@/views/LockScreen.vue"),
      meta: {
        documentTitle: "Your account is Locked | LinkedClient",
        layout: AuthLayout,
      },
    },
    {
      path: "/unsubscribe/:id",
      name: "unsubscribe",
      component: () => import("@/views/Unsubscribe.vue"),
      meta: {
        documentTitle: "Unsubscribe | LinkedClient",
        layout: AuthLayout,
      },
    },
    {
      path: "*",
      component: () => import("@/views/e404.vue"),
      meta: {
        documentTitle: "404 | LinkedClient",
        layout: AuthLayout,
      },
    },
  ],
});

router.afterEach(async (to) => {
  if (
    typeof to.meta["documentTitle"] === "string" &&
    to.meta["documentTitle"].length > 0
  ) {
    document.title = to.meta["documentTitle"];
  } else {
    document.title = "LinkedClient";
  }

  await store.dispatch("toggleLoader", false);
});

router.beforeEach(async (to, _, next) => {
  await store.dispatch("toggleLoader", true);
  await store.dispatch("auth/setTokensFromLocalStorage");
  const { accessToken, refreshToken } = store.getters["auth/getTokens"];

  // If next page is login, always allow to go there
  if (to.name === "login") {
    return next();
  }

  // If page does not require us to verify auth, allow to go there
  if (!to.meta.requiresAuth) {
    return next();
  }

  // If user does not have access token or refresh token, redirect to login page
  if (!accessToken || !refreshToken) {
    return next({
      path: "/login",
      params: { nextUrl: to.fullPath },
    });
  }

  await store.dispatch("auth/setTokensFromLocalStorage");
  await store.dispatch("auth/fetchUserData");

  const userIsLoggedIn = store.getters["auth/isLoggedIn"];
  const userIsAdmin = store.getters["auth/isAdmin"];
  const userIsSuperAdmin = store.getters["auth/isSuperAdmin"];
  const organizationIsLocked = store.getters["auth/isOrganizationLocked"];
  const user = store.getters["auth/getUser"];

  if (window.hj && user) {
    try {
      window.hj("identify", user._id, {
        email: user.email,
        first_name: user.firstName,
        created: user.createdAt,
        company: user.organization,
      });
    } catch (err) {
      console.log(err);
    }
  }

  if (organizationIsLocked) {
    return next({ name: "lockScreen" });
  }

  // If somehow store does not contain information about user (is not logged in) redirect to login page
  if (!userIsLoggedIn) {
    return next({
      path: "/login",
      params: { nextUrl: to.fullPath },
    });
  }

  // If target route is marked as "guest" and user is logged in, we redirect that user to dashboard page,
  // they can't access guest pages when they are logged in
  if (to.meta.guest && userIsLoggedIn) {
    return next({ name: "dashboard" });
  }

  // If target route is marked as accessible by admins and user is admin, allow to go there
  if (to.meta.isAdmin && !userIsAdmin) {
    return next({ name: "dashboard" });
  }

  // If target route is marked as accessible by super admins and user is super admin, allow to go there
  if (to.meta.isSuperAdmin && !userIsSuperAdmin) {
    return next({ name: "dashboard" });
  }

  // If everything is correct allow to go further
  return next();
});

export default router;
