var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.label.length > 0)?_c('div',{staticClass:"text--secondary text-normal mb-3"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-row justify-between align-stretch"},_vm._l((_vm.options),function(o,ix){return _c('div',{key:o.value,staticClass:"option d-flex flex-column justify-center align-center grow py-4 px-2 text-center cursor-pointer",class:{
        'option--active': o.value === _vm.value && !o.disabled && !_vm.disabled,
        'option--disabled': o.disabled === true || _vm.disabled === true,
        'rounded-l-lg': ix === 0,
        'rounded-r-lg': ix === _vm.options.length - 1,
      },staticStyle:{"height":"auto"},style:({
        width: 'calc(100% / ' + _vm.options.length + ')',
      }),on:{"click":() => _vm.onOptionClick(o)}},[(typeof o.icon === 'string')?_c('div',[_c('v-icon',{attrs:{"color":o.value === _vm.value ? 'white' : 'grey'}},[_vm._v(" "+_vm._s(o.icon)+" ")])],1):(_vm.isArray(o.icon))?_c('div',_vm._l((o.icon),function(ic){return _c('v-icon',{key:o.value + '_' + ic,attrs:{"color":o.value === _vm.value ? 'white' : 'grey'}},[_vm._v(" "+_vm._s(ic)+" ")])}),1):_vm._e(),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(o.text))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }