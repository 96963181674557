<template>
  <v-list-item
    v-if="canBeShown && isInternal"
    :to="entry.route"
    link
    color="primary"
  >
    <v-list-item-icon class="mr-5" v-if="icon !== false">
      <v-badge
        v-if="entry.route === '/inbox'"
        dot
        color="secondary"
        :value="unseenInboxMessages"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-badge>
      <v-icon v-else>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title
        :class="{
          'text-body-1': !nested,
          'text-body-2': nested,
        }"
      >
        <span>
          {{ entry.text }}
        </span>
        <v-icon class="mr-1" v-if="entry['new']"> mdi-new-box</v-icon>
        <v-badge
          class="ml-2"
          v-if="entry.route === '/inbox'"
          :value="unseenInboxMessages"
          :content="unseenInboxMessages"
          color="secondary"
        />
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <v-list-item
    v-else-if="canBeShown && isExternal"
    :href="entry.route"
    target="_blank"
    link
  >
    <v-list-item-icon class="mr-5" v-if="icon !== false">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title
        :class="{
          'text-body-1': !nested,
          'text-body-2': nested,
        }"
      >
        {{ entry.text }}
        <v-icon class="mr-1" v-if="entry['new']"> mdi-new-box</v-icon>
        <v-icon small>mdi-open-in-new</v-icon>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <v-list-item
    v-else-if="canBeShown && isCustomAction"
    @click="handleCustomAction"
    target="_blank"
    link
  >
    <v-list-item-icon class="mr-5" v-if="icon !== false">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title
        :class="{
          'text-body-1': !nested,
          'text-body-2': nested,
        }"
      >
        {{ entry.text }}

        <v-icon class="mr-1" v-if="entry['new']"> mdi-new-box</v-icon>
        <v-icon small v-if="isExternal">mdi-open-in-new</v-icon>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <v-list-group v-else-if="canBeShown && isGroup" :value="false" no-action>
    <template v-slot:activator>
      <v-list-item-icon class="mr-5" v-if="icon !== false">
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="text-body-1">
          {{ entry.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <NavigationItem
      v-for="(nested, index) in entry.nested"
      :key="index"
      :nested="true"
      :entry="nested"
    />
  </v-list-group>
</template>
<script>
import NavigationItem from "./NavigationItem.vue";

export default {
  name: "NavigationItem",
  components: {
    NavigationItem,
  },
  computed: {
    /**
     * Is admin computed
     */
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    /**
     * Is super admin computed
     */
    isSuperAdmin() {
      return this.$store.getters["auth/isSuperAdmin"];
    },
    /**
     * Should we use predefined custom action when clicked on item
     */
    isCustomAction() {
      return (
        typeof this.entry.customAction === "string" &&
        ["billing", "support"].includes(this.entry.customAction)
      );
    },
    /**
     * Count of unseen inbox messages
     */
    unseenInboxMessages() {
      return this.$store.getters.unseenInboxMessages;
    },
    /**
     * Is group
     */
    isGroup() {
      return Array.isArray(this.entry.nested);
    },
    /**
     * Is redirection internal
     */
    isInternal() {
      return (
        typeof this.entry.route === "string" &&
        this.entry.route.length > 0 &&
        (this.entry.external === false ||
          typeof this.entry.external === "undefined")
      );
    },
    /**
     * Returns an icon identifier if found
     */
    icon() {
      if (typeof this.entry.icon === "string" && this.entry.icon.length > 0) {
        return this.entry.icon;
      }

      return false;
    },
    /**
     * Is redirection external
     */
    isExternal() {
      return this.entry.external === true;
    },
    /**
     * Are conditions met to be shown on the menu
     */
    canBeShown() {
      if (typeof this.entry.conditions !== "undefined") {
        return Object.entries(this.entry.conditions)
          .map(([key, value]) => {
            if (key === "isAdmin" && value === true) {
              return this.isAdmin;
            }

            if (key === "isSuperAdmin" && value === true) {
              return this.isSuperAdmin;
            }

            return true;
          })
          .every((entry) => entry === true);
      }

      return true;
    },
  },
  methods: {
    /**
     * Triggered when item has been marked as "custom action"
     */
    async handleCustomAction() {
      if (!this.isCustomAction) {
        return;
      }

      switch (this.entry.customAction) {
        case "billing": {
          try {
            const billingResp = await this.$http.get("/billing");
            window.open(billingResp.data.url, "_blank");
          } catch (err) {
            this.$message({
              type: "error",
              msg: "Failed to open your billing page. Please try again or contact our support if the problem persists",
            });
          }
          break;
        }
        case "support": {
          await this.$store.dispatch("setShowSupport", true);
          break;
        }
      }
    },
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    entry: {
      type: Object,
      required: true,
    },
  },
};
</script>
