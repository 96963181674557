import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";
import Vue from "vue";
import { SYSTEM_PALETTE } from "@/config/colors";
import { getThemeColors } from "@/plugins/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: getThemeColors(SYSTEM_PALETTE),
    },
  },
});
