var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.canBeShown && _vm.isInternal)?_c('v-list-item',{attrs:{"to":_vm.entry.route,"link":"","color":"primary"}},[(_vm.icon !== false)?_c('v-list-item-icon',{staticClass:"mr-5"},[(_vm.entry.route === '/inbox')?_c('v-badge',{attrs:{"dot":"","color":"secondary","value":_vm.unseenInboxMessages}},[_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1):_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{class:{
        'text-body-1': !_vm.nested,
        'text-body-2': _vm.nested,
      }},[_c('span',[_vm._v(" "+_vm._s(_vm.entry.text)+" ")]),(_vm.entry['new'])?_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-new-box")]):_vm._e(),(_vm.entry.route === '/inbox')?_c('v-badge',{staticClass:"ml-2",attrs:{"value":_vm.unseenInboxMessages,"content":_vm.unseenInboxMessages,"color":"secondary"}}):_vm._e()],1)],1)],1):(_vm.canBeShown && _vm.isExternal)?_c('v-list-item',{attrs:{"href":_vm.entry.route,"target":"_blank","link":""}},[(_vm.icon !== false)?_c('v-list-item-icon',{staticClass:"mr-5"},[_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{class:{
        'text-body-1': !_vm.nested,
        'text-body-2': _vm.nested,
      }},[_vm._v(" "+_vm._s(_vm.entry.text)+" "),(_vm.entry['new'])?_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-new-box")]):_vm._e(),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)],1)],1):(_vm.canBeShown && _vm.isCustomAction)?_c('v-list-item',{attrs:{"target":"_blank","link":""},on:{"click":_vm.handleCustomAction}},[(_vm.icon !== false)?_c('v-list-item-icon',{staticClass:"mr-5"},[_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{class:{
        'text-body-1': !_vm.nested,
        'text-body-2': _vm.nested,
      }},[_vm._v(" "+_vm._s(_vm.entry.text)+" "),(_vm.entry['new'])?_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-new-box")]):_vm._e(),(_vm.isExternal)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")]):_vm._e()],1)],1)],1):(_vm.canBeShown && _vm.isGroup)?_c('v-list-group',{attrs:{"value":false,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(_vm.icon !== false)?_c('v-list-item-icon',{staticClass:"mr-5"},[_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.entry.text)+" ")])],1)]},proxy:true}])},_vm._l((_vm.entry.nested),function(nested,index){return _c('NavigationItem',{key:index,attrs:{"nested":true,"entry":nested}})}),1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }