<template>
  <div class="first-steps-layout" ref="vantaRef">
    <LayoutMessages />
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm12 md10 lg8 xl6>
          <!-- when deleting thi ALERT, remember to change max-height: 49vh; to 54 in meetingurlconguration component!  -->

          <img src="@/assets/img/linkedclient-full-white.png" class="logo" />
          <v-spacer></v-spacer>
          <v-card rounded class="rounded-xl" flat>
            <slot />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <div class="first-steps-layout__support">
      <InitialWizardLayoutSupportButtons />
    </div>
  </div>
</template>
<script>
import GLOBE from "vanta/dist/vanta.globe.min";
import LayoutMessages from "@/components/LayoutMessages";
import InitialWizardLayoutSupportButtons from "@/components/InitialWizardLayoutSupportButtons";

export default {
  name: "InitialWizardLayout",
  components: {
    LayoutMessages,
    InitialWizardLayoutSupportButtons,
  },
  mounted() {
    this.vantaEffect = GLOBE({
      el: this.$refs.vantaRef,
      mouseControls: true,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x7421fc,
      color2: 0xbe96ff,
      backgroundColor: 0x171738,
    });
  },
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
    }
  },
};
</script>
<style lang="scss">
.first-steps-layout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__support {
    position: absolute !important;
    top: 30px;
    right: 30px;
  }
}
</style>
