<template>
  <div>
    <div class="text--secondary text-normal mb-3" v-if="label.length > 0">
      {{ label }}
    </div>
    <div class="d-flex flex-row justify-between align-stretch">
      <div
        v-for="(o, ix) in options"
        :key="o.value"
        class="option d-flex flex-column justify-center align-center grow py-4 px-2 text-center cursor-pointer"
        style="height: auto"
        :style="{
          width: 'calc(100% / ' + options.length + ')',
        }"
        @click="() => onOptionClick(o)"
        :class="{
          'option--active': o.value === value && !o.disabled && !disabled,
          'option--disabled': o.disabled === true || disabled === true,
          'rounded-l-lg': ix === 0,
          'rounded-r-lg': ix === options.length - 1,
        }"
      >
        <div v-if="typeof o.icon === 'string'">
          <v-icon :color="o.value === value ? 'white' : 'grey'">
            {{ o.icon }}
          </v-icon>
        </div>
        <div v-else-if="isArray(o.icon)">
          <v-icon
            v-for="ic in o.icon"
            :key="o.value + '_' + ic"
            :color="o.value === value ? 'white' : 'grey'"
          >
            {{ ic }}
          </v-icon>
        </div>
        <div class="mt-2">{{ o.text }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.checkDisabled();
  },
  watch: {
    value() {
      this.$nextTick(() => this.checkDisabled());
    },
  },
  computed: {},
  methods: {
    onOptionClick(option) {
      if (!option.disabled && !this.disabled) {
        this.$emit("input", option.value);
      }
    },
    isArray(a) {
      return Array.isArray(a);
    },
    checkDisabled() {
      const index = this.options.findIndex(
        (option) => option.value === this.value
      );
      if (index === -1) return;
      if (!this.options[index].disabled) return;

      for (const option of this.options) {
        if (!option.disabled) {
          this.$emit("input", option.value);
          break;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.option {
  background: rgba(0, 0, 0, 0.1);

  &--active {
    background-color: var(--primary-lighten-2) !important;
    color: white !important;
  }

  &--disabled {
    cursor: not-allowed !important;
  }
}
</style>
