<template>
  <div>
    <v-alert dense :type="color" class="text-14 my-0 rounded">
      <v-row align="center">
        <v-col class="grow">
          <p class="py-0 mt-0 mb-1 font-weight-bold" v-if="title.length > 0">
            {{ title }}
          </p>
          <div v-if="message.length > 0">
            {{ message }}
          </div>
        </v-col>
        <v-col class="shrink" v-if="action && action.buttonText">
          <v-btn color="white" small outlined @click="performAction">
            {{ action.buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "info",
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      required: true,
    },
    notificationId: {
      type: String,
      required: true,
    },
    action: {
      type: Object,
      default: () => {
        return {
          buttonText: "Got it!",
          type: "INFORMATION",
          data: "",
        };
      },
    },
  },
  methods: {
    async removeNotification() {
      this.$store.commit(
        "dashboardNotifications/removeNotification",
        this.notificationId
      );
    },
    async performAction() {
      try {
        switch (this.action.type) {
          case "INFORMATION":
            await this.removeNotification();
            await this.$http.delete("/notifications/" + this.notificationId);
            break;
          case "REDIRECT":
            await this.removeNotification();
            //We need this stupid replace because JS is stupid and wont work without
            this.$router.push(
              // eslint-disable-next-line quotes
              JSON.parse(this.action.data.replace(/&quot;/gi, '"'))
            );
            await this.$http.delete("/notifications/" + this.notificationId);

            break;
          case "LINK":
            await this.removeNotification();
            window.open(this.action.data, "_blank");
            await this.$http.delete("/notifications/" + this.notificationId);
            break;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
