<template>
  <v-tooltip bottom :disabled="disableTooltip">
    <template v-slot:activator="{ on, attrs }">
      <span :class="displayClass" v-bind="attrs" v-on="on">
        <slot name="display" v-bind:date="relativeDate">
          {{ relativeDate }}
        </slot>
      </span>
    </template>
    <slot name="tooltip" v-bind:date="readableDate">
      {{ readableDate }}
    </slot>
  </v-tooltip>
</template>
<script>
import {
  getReadableDate,
  toRelativeCalendar,
} from "../../helpers/utils.helper";

export default {
  name: "relative-date",
  props: {
    date: [Date, Number, String],
    prefix: {
      type: String,
      default: "",
    },
    displayClass: {
      type: String,
      default: "",
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Readable date
     */
    readableDate() {
      return getReadableDate(this.date);
    },

    /**
     * Relative date
     */
    relativeDate() {
      return toRelativeCalendar(this.date, true);
    },
  },
};
</script>
