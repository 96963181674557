import Axios from "../../plugins/axios";
import store from "../index";

export default {
  namespaced: true,
  state: () => ({
    loading: true,
    showDialog: false,
    survey: {
      companyName: "",
      companyLocation: "",
      companyTitle: "",
      industry: "",
      companySize: "",
      targetAudience: "",
      linkedinProfile: "",
      companyWebsite: "",
      competitors: "",
      goal: "",
      desiredMeetings: "1",
      salesGrowth: "",
      salesStrategy: "",
      currentGrowth: "",
      timeToFirstSale: "",
      conversionRate: "",
      brandDescription: "",
      companyOffer: "",
      longtermOffer: "",
      uniqueSellingPoint: "",
      caseStudy: "",
      faq: "",
      jobTitleAndWorkNature: "",
      professionalSkillsAndExperience: "",
      specialEducationQualifications: "",
      personalInterestsAndHobbies: "",
      networkingAndCollaborationStyle: "",
      approachToWorkCareerBalance: "",
      personalityType: "",
    },
  }),
  getters: {
    /**
     * Returns completion of the user survey
     * @returns {number}
     */
    completion(state) {
      const values = Object.values(state.survey);
      const completed = values.filter(
        (value) => typeof value === "string" && value.length > 0
      );

      return (completed.length / values.length) * 100;
    },
    /**
     * Return loading state
     * @param {any} state
     * @returns {boolean}
     */
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    /**
     * Set loading to certain state
     * @param {any} state
     * @param {boolean} value
     */
    setLoading(state, value) {
      state.loading = value;
    },
    /**
     * Set showDialog variable
     * @param {any} state
     * @param {boolean} value
     */
    setShowDialog(state, value) {
      state.showDialog = value;
    },
    /**
     * Set survey in state
     * @param {any} state
     * @param {object} survey
     */
    setSurvey(state, survey) {
      Object.assign(state.survey, survey);
    },
  },
  actions: {
    /**
     * Set loading to certain state
     * @param {any} context
     * @param {boolean} value
     */
    setLoading(context, value) {
      context.commit("setLoading", value);
    },
    /**
     * Set showDialog variable to certain state
     * @param {any} context
     * @param {boolean} value
     */
    setShowDialog(context, value) {
      context.commit("setShowDialog", value);
    },
    /**
     * Set survey
     * @param {any} context
     * @param {object} survey
     */
    setSurvey(context, survey) {
      context.commit("setSurvey", survey);
    },
    /**
     * Perform update on a survey
     */
    async updateSurvey(context) {
      if (context.state.loading) return;

      context.commit("setLoading", true);
      try {
        const response = await Axios.post(
          "/users/me/survey",
          context.state.survey
        );

        if (!response.success) {
          throw new Error("Invalid response");
        }

        context.commit("setSurvey", response.user.userSurvey);
        store.dispatch("layoutMessages/addMessage", {
          type: "success",
          msg: "Survey updated successfully",
        });

        context.commit("setShowDialog", false);
      } catch (error) {
        console.error(error);

        store.dispatch("layoutMessages/addMessage", {
          type: "error",
          msg: "We failed to update your user survey",
        });
      }

      context.commit("setLoading", false);
    },
    /**
     * Load users survey data
     */
    async loadSurvey(context) {
      context.commit("setLoading", true);

      try {
        const response = await Axios.get("/users/me/survey");
        if (!response["success"]) {
          console.error(
            "[store/usersSurvey@loadSurvey] Invalid response from survey data"
          );
          context.commit("setLoading", false);
          return;
        }

        const survey = response.data.survey;

        if (survey && typeof survey["desiredMeetings"] === "string") {
          if (survey["desiredMeetings"].length === 0) {
            survey.desiredMeetings = "1";
          } else {
            survey.desiredMeetings = survey.desiredMeetings?.trim();
          }
        }

        const user = store.getters["auth/getUser"];

        if (typeof survey["companyName"] === "undefined") {
          survey.companyName = user.organization.name;
        }

        context.commit("setSurvey", survey);
      } catch (error) {
        console.error(
          "[store/usersSurvey@loadSurvey] Error fetching survey",
          error
        );
      }

      context.commit("setLoading", false);
    },
  },
};
