<template>
  <v-app-bar
    :class="{
      'admin-toolbar': isAdminPanel,
    }"
    app
    dense
    style="left: 0; z-index: 150"
    flat
    class="white"
    height="64"
  >
    <router-link class="navigation-logo mr-4" to="/">
      <img
        src="@/assets/img/lc-logo-full.png"
        alt="logo"
        class="navigation-logo__image"
      />
    </router-link>

    <v-divider vertical v-if="isAdminPanel" />

    <v-toolbar-title class="font-weight-black ml-4">
      {{ pageTitle }}
    </v-toolbar-title>
    <v-switch
      v-if="!isRegularUser && !isOwnerOnly"
      v-model="viewFullOrganization"
      @change="updateViewingOptions()"
      label="View full organization"
      class="ma-4 organization-switch"
      dense
    ></v-switch>
    <v-fade-transition>
      <v-chip
        color="secondary"
        class="ma-2"
        close
        v-if="doppelgangerMode"
        @click:close="$store.dispatch('doppelganger/clearDoppelganger')"
      >
        You are now viewing as {{ doppelgangerName }}
      </v-chip>
    </v-fade-transition>

    <v-spacer></v-spacer>
    <v-btn icon @click="$router.push('/inbox')">
      <v-badge
        color="primary"
        :value="unseenInboxMessages"
        :content="unseenInboxMessages"
      >
        <v-icon>mdi-message-outline</v-icon>
      </v-badge>
    </v-btn>
    <TooltipAdvanced
      position="left-end"
      :hover="false"
      width="500px"
      ref="notificationsTooltip"
    >
      <template v-slot:reference>
        <v-btn icon>
          <v-badge
            color="primary"
            :value="$store.state.dashboardNotifications.notifications.length"
            :content="$store.state.dashboardNotifications.notifications.length"
          >
            <v-icon>mdi-bell-outline</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <template>
        <div class="text-14">
          <div class="card-title mb-2 d-flex">
            Notifications
            <v-spacer />
            <v-btn
              x-small
              icon
              color="error"
              @click="$refs.notificationsTooltip.close()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <DashboardNotifications />
        </div>
      </template>
    </TooltipAdvanced>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed v-bind="attrs" v-on="on" icon @click="logout">
          <v-icon>mdi-power</v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>
  </v-app-bar>
</template>
<script>
import TooltipAdvanced from "@/components/TooltipAdvanced";
import DashboardNotifications from "@/components/Dashboard/DashboardNotifications";

export default {
  components: { TooltipAdvanced, DashboardNotifications },
  data() {
    return {
      title: "Dashboard",
      viewFullOrganization: true,
    };
  },
  computed: {
    /**
     * Is current view
     */
    isAdminPanel() {
      return this.$route.path.includes("admin");
    },
    isRegularUser() {
      return this.$store.getters["auth/isRegularUser"];
    },
    isOwnerOnly() {
      return this.$store.getters["auth/isOwnerOnly"];
    },
    doppelgangerMode() {
      return (
        this.$store.getters["auth/isSuperAdmin"] &&
        this.$store.getters["doppelganger/isDoppelgangerEnabled"]
      );
    },
    doppelgangerName() {
      const doppelganger = this.$store.getters["doppelganger/doppelganger"];

      if (!doppelganger) {
        return "Unknown";
      }

      return doppelganger.firstName + " " + doppelganger.lastName;
    },
    unseenInboxMessages() {
      return this.$store.getters.unseenInboxMessages;
    },
    pageTitle() {
      if (this.$route.meta.pageTitle) return this.$route.meta.pageTitle;
      return this.$store.state.dashboardTitle;
    },
  },
  mounted() {
    this.viewFullOrganization = localStorage.getItem("lc_view_all") === "Yes";
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/clearDoppelganger");
      this.$store.dispatch("auth/logout");
    },
    updateViewingOptions() {
      localStorage.setItem(
        "lc_view_all",
        this.viewFullOrganization ? "Yes" : "No"
      );
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
.admin-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.organization-switch {
  .v-input__slot {
    padding-top: 20px;

    .v-label {
      font-size: 12px;
    }
  }
}
</style>
