<template>
  <div class="auth-layout">
    <LayoutMessages />
    <div class="auth-layout__image auth-layout__image--left" />
    <div class="auth-layout__image auth-layout__image--right" />

    <div class="auth-layout__content">
      <slot />
    </div>
  </div>
</template>

<script>
import LayoutMessages from "@/components/LayoutMessages";

export default {
  name: "AuthLayout",
  components: { LayoutMessages },
};
</script>
<style lang="scss">
.auth-layout {
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(
    145deg,
    var(--primary-darken-4) 0%,
    var(--secondary-darken-4) 100%
  );

  &__image {
    position: absolute;
    top: 0;
    height: 100%;
    width: 610px;
    background-repeat: no-repeat;
    background-size: cover;

    &--left {
      background-image: url("@/assets/img/hero-left.webp");
      left: -100px;
      background-position: left;
    }

    &--right {
      background-image: url("@/assets/img/hero-right.webp");
      right: -100px;
      background-position: right;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
</style>
