<template>
  <div id="app">
    <v-app>
      <component :is="$route.meta.layout || 'div'">
        <router-view />
      </component>
    </v-app>
  </div>
</template>
<script>
export default {
  methods: {
    /**
     * Prepare display object which will be stored in main store of the app
     * @param {number} width
     */
    prepareDisplayStateBasedOnDisplayWidth(width) {
      const displayObject = {
        isExtraSmall: false,
        isSmall: false,
        isMedium: false,
        isLarge: false,
        isExtraLarge: false,
      };

      if (width < 600) {
        displayObject.isExtraSmall = true;
      } else if (width >= 600 && width < 960) {
        displayObject.isSmall = true;
      } else if (width >= 960 && width < 1264) {
        displayObject.isMedium = true;
      } else if (width >= 1264 && width < 1904) {
        displayObject.isLarge = true;
      } else {
        displayObject.isExtraLarge = true;
      }

      return displayObject;
    },
  },
  mounted() {
    const displayObject = this.prepareDisplayStateBasedOnDisplayWidth(
      document.documentElement.clientWidth
    );
    this.$store.dispatch("setDisplayObject", displayObject);

    const self = this;

    window.addEventListener("resize", function () {
      const displayObject = self.prepareDisplayStateBasedOnDisplayWidth(
        document.documentElement.clientWidth
      );

      self.$store.dispatch("setDisplayObject", displayObject);
    });
  },
};
</script>
