<template>
  <div class="multiple-condition-selector">
    <div class="multiple-condition-selector__actions">
      <v-form @submit="insertValue">
        <v-text-field
          v-if="!areItemsProvided && !isApiCall"
          v-model="result"
          :label="label"
          :loading="loading"
          :disabled="loading"
          hide-details
          outlined
          :color="color"
          dense
        ></v-text-field>
        <v-autocomplete
          v-else-if="isApiCall"
          v-model="result"
          :items="apiResponse"
          no-filter
          hide-details
          outlined
          dense
          ref="conditionAutocomplete"
          :search-input.sync="searchInput"
          @update:search-input="onAutocompleteSearchInput"
          :item-value="itemValue"
          clearable
          @input="onAutocompleteInput"
          :label="label"
          :loading="loading || apiLoading"
          :disabled="loading || apiLoading"
          :color="color"
        >
          <template v-slot:item="data">
            {{ getItemText(data.item) }}
          </template>
          <template v-slot:selection="data">
            {{ getItemText(data.item) }}
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-else-if="!isApiCall"
          v-model="result"
          :items="notPickedItems"
          no-filter
          outlined
          hide-details
          dense
          :search-input.sync="searchInput"
          ref="conditionAutocomplete"
          :item-value="itemValue"
          clearable
          @input="onAutocompleteInput"
          :label="label"
          :loading="loading || apiLoading"
          :disabled="loading || apiLoading"
          :color="color"
        >
          <template v-slot:item="data">
            {{ getItemText(data.item) }}
          </template>
          <template v-slot:selection="data">
            {{ getItemText(data.item) }}
          </template>
        </v-autocomplete>
      </v-form>
    </div>
    <v-chip-group column v-if="tags.length > 0" class="mt-2">
      <v-tooltip top v-for="tag of tags" :key="tag.id">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            close
            small
            :color="tagColorBasedOnCondition(tag.condition)"
            @click="changeCondition(tag.id)"
            @click:close="removeTag(tag.id)"
            v-bind="attrs"
            v-on="on"
          >
            <span class="mr-1">
              {{ limitTextLength(tag.text, 32) }}
            </span>
            <v-icon size="16" v-if="tag.condition !== '='">
              {{ conditionIcon(tag.condition) }}
            </v-icon>
            <v-divider vertical class="ml-1" />
          </v-chip>
        </template>
        <span>{{ getReadableExplanationForTag(tag) }}</span>
      </v-tooltip>
    </v-chip-group>
    <div class="multiple-condition-selector__empty" v-else>
      No filtration selected
    </div>
  </div>
</template>
<style lang="scss">
.multiple-condition-selector {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__empty {
    width: 100%;
    font-size: 12px;
    text-align: center;
    margin-top: 12px;
  }

  &__mode {
    font-size: 10px;
  }

  &__actions {
    margin-top: 15px;
  }
}
</style>
<script>
import { v4 as uuidv4 } from "uuid";
import { limitTextLength } from "@/helpers/text.helpers";

export default {
  data() {
    return {
      limitTextLength,
      firstApiLoad: false,
      apiLoading: false,
      searchInput: "",
      result: "",
      debouncer: null,
      apiResponse: [],
      tags: [],
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: [String, Function],
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    color: {
      type: String,
      default: "primary",
    },
    items: {
      type: Array,
    },
    requestOptions: {
      type: [Object, Function],
    },
    allowedConditions: {
      type: Array,
      default: () => ["=", "!=", ":", "!:"],
    },
    label: {
      type: String,
      default: undefined,
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Computed for checking if the autocomplete should call API
     * @returns {boolean} true or false
     */
    isApiCall() {
      if (typeof this.requestOptions === "undefined") {
        return false;
      }

      return (
        typeof (
          this.requestOptions === "object" ||
          typeof this.requestOptions === "function"
        ) && this.requestOptions !== null
      );
    },
    /**
     * Computed for checking if item field is provided, if it is then we switch from v-text-field to v-autocomplete
     * @returns {boolean} true or false
     */
    areItemsProvided() {
      return (
        typeof this.items !== "undefined" &&
        Array.isArray(this.items) &&
        this.items.every(
          (value) =>
            typeof value === "object" &&
            value !== null &&
            Object.keys(value).includes(this.itemValue)
        )
      );
    },
    /**
     * Not picked items
     */
    notPickedItems() {
      return this.items.filter((item) => {
        const selectedTag = this.tags.find((tag) => tag.value === item.value);
        return !selectedTag;
      });
    },
  },
  methods: {
    /**
     * Get item text
     * @param {Object} item
     * @returns {string}
     */
    getItemText(item) {
      if (typeof this.itemText === "function") {
        return this.itemText(item);
      }

      if (typeof this.itemText === "string") {
        return item[this.itemText];
      }

      return item["text"];
    },
    /**
     * Set tags manually
     * @param {any[]} tags
     * @public
     */
    setTagsManually(tags) {
      if (Array.isArray(tags)) {
        this.tags = tags;
      }
    },
    /**
     * Debounce function
     * @param {() => void} cb
     * @param {number} timeout default set to 300
     */
    debounce(cb, timeout = 300) {
      clearTimeout(this.debouncer);
      this.debouncer = setTimeout(() => {
        cb();
      }, timeout);
    },
    /**
     * Fetch items from our API for autocomplete
     */
    async fetchItemsFromAPI() {
      if (
        typeof this.requestOptions === "undefined" ||
        this.loading ||
        this.apiLoading
      ) {
        return;
      }

      this.apiLoading = true;

      try {
        let requestOptions = {};

        if (typeof this.requestOptions === "function") {
          requestOptions = this.requestOptions(this);
        } else {
          requestOptions = this.requestOptions;
        }

        const { query, path, responseObjectName } = requestOptions;
        const searchParams = new URLSearchParams(query);

        if (
          typeof this.searchInput === "string" &&
          this.searchInput.length > 0
        ) {
          searchParams.append("search", this.searchInput);
        }

        const params = searchParams.toString();
        const response = await this.$http.get(
          params.length > 0 ? `${path}?${params}` : path
        );

        if (!response.success) {
          throw new Error("Request was invalid!");
        }

        this.firstApiLoad = true;
        this.apiResponse = response[responseObjectName];
      } catch (error) {
        console.error(error);
        this.$message({
          type: "error",
          msg: `Failed to fetch the required data. Please try again or contact our support if the problem persists`,
        });
      }

      this.apiLoading = false;
    },
    /**
     * Triggered when user starts typing in autocomplete field
     */
    onAutocompleteSearchInput() {
      if (typeof this.searchInput === "string" && this.searchInput.length > 0) {
        const self = this;
        this.debounce(function () {
          self.fetchItemsFromAPI();
        }, 500);
      }
    },
    /**
     * Triggered when autocomplete value has been changed
     */
    onAutocompleteInput() {
      if (typeof this.result !== "string" || this.result.length === 0) {
        return;
      }

      let foundItem = null;

      if (typeof this.items !== "undefined") {
        foundItem = this.items.find(
          (item) => item[this.itemValue] === this.result
        );
      } else if (typeof this.apiResponse !== "undefined") {
        foundItem = this.apiResponse.find(
          (item) => item[this.itemValue] === this.result
        );
      }

      if (foundItem) {
        this.tags.push({
          id: uuidv4(),
          condition: this.allowedConditions[0],
          text: this.getItemText(foundItem),
          value: foundItem[this.itemValue],
        });

        this.$emit("change", this.tags);
      }

      // Set autocomplete component to initial state
      this.$refs["conditionAutocomplete"].blur();
      this.$refs["conditionAutocomplete"].setValue(null);

      // Set our local variables to initial state
      this.searchInput = "";
      this.result = "";
    },

    /**
     * Insert value on submit
     * @param {SubmitEvent} event
     */
    insertValue(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.result.length === 0) {
        return;
      }

      if (this.areItemsProvided || this.isApiCall) {
        if (typeof this.items !== "undefined") {
          let foundItem = null;

          if (
            typeof this.searchInput === "string" &&
            this.searchInput.length > 0
          ) {
            foundItem = this.items.find(
              (item) => this.getItemText(foundItem) === this.searchInput
            );
          }

          if (foundItem === null) {
            foundItem = this.items.find(
              (item) => item[this.itemValue] === this.result
            );
          }

          if (foundItem) {
            this.tags.push({
              id: uuidv4(),
              condition: this.allowedConditions[0],
              text: this.getItemText(foundItem),
              value: foundItem[this.itemValue],
            });
            this.$emit("change", this.tags);
          }
        } else if (typeof this.apiResponse !== "undefined") {
          let foundItem = this.apiResponse.find(
            (item) => item[this.itemValue] === this.result
          );

          if (foundItem) {
            this.tags.push({
              id: uuidv4(),
              condition: this.allowedConditions[0],
              text: this.getItemText(foundItem),
              value: foundItem[this.itemValue],
            });
            this.$emit("change", this.tags);
          }
        }
      } else {
        const alreadyExists = this.tags.find(
          (tag) => tag.value.toLowerCase() === this.result.toLowerCase()
        );

        if (!alreadyExists) {
          this.tags.push({
            id: uuidv4(),
            text: this.result,
            condition: this.allowedConditions[0],
            value: this.result,
          });
          this.$emit("change", this.tags);
        }
      }

      if (this.$refs["conditionAutocomplete"]) {
        this.$refs["conditionAutocomplete"].blur();
      }

      this.result = "";
      this.searchInput = "";
    },
    /**
     * Get an icon based on condition
     * @param {"=" | "!=" | ":" | "!:"} condition
     */
    conditionIcon(condition) {
      switch (condition) {
        case "=": {
          return "mdi-equal";
        }
        case "!=": {
          return "mdi-not-equal-variant";
        }
        case ":": {
          return "mdi-contain";
        }
        case "!:": {
          return "mdi-cancel";
        }
        default: {
          return "mdi-error";
        }
      }
    },
    /**
     * Get color for chip based on the condition
     * @param {"=" | "!=" | ":" | "!:"} condition
     */
    tagColorBasedOnCondition(condition) {
      switch (condition) {
        case "=": {
          return "gray";
        }
        case ":": {
          return "success dark-4";
        }
        case "!=": {
          return "error dark-4";
        }
        case "!:": {
          return "error dark-3";
        }
        default: {
          return "gray";
        }
      }
    },
    /**
     * Remove tag from selection
     * @param {string} id
     */
    removeTag(id) {
      const foundEntryIndex = this.tags.findIndex((entry) => entry.id === id);
      if (foundEntryIndex !== -1) {
        this.tags.splice(foundEntryIndex, 1);
        this.$emit("change", this.tags);
      }
    },
    /**
     * Change condition of singular tag
     * @param {string} id
     */
    changeCondition(id) {
      const foundEntryIndex = this.tags.findIndex((entry) => entry.id === id);

      this.allowedConditions.findIndex((condition) => condition);

      if (foundEntryIndex !== -1) {
        const foundIndex = this.allowedConditions.findIndex(
          (condition) => condition === this.tags[foundEntryIndex].condition
        );

        if (foundIndex !== -1) {
          const nextCondition = this.allowedConditions[foundIndex + 1];

          if (typeof nextCondition !== "undefined") {
            this.tags[foundEntryIndex].condition = nextCondition;
          } else {
            this.tags[foundEntryIndex].condition = this.allowedConditions[0];
          }
        }

        this.$emit("change", this.tags);
      }
    },
    /**
     * Get explanation what condition selected by user does
     * @param {object} tag
     */
    getReadableExplanationForTag(tag) {
      switch (tag.condition) {
        case "=": {
          return `Is the same as "${tag.text}"`;
        }
        case "!=": {
          return `Is not the same as "${tag.text}"`;
        }
        case ":": {
          return `Contain "${tag.text}" somewhere in value`;
        }
        case "!:": {
          return `Does not contain "${tag.text}" in value`;
        }
        default: {
          return "Missing explanation";
        }
      }
    },
    /**
     * Reset all values to default state
     * @public
     */
    resetValues() {
      this.apiResponse = [];
      this.tags = [];
      this.apiLoading = false;
      this.result = "";
      this.searchInput = "";
    },
  },
  async mounted() {
    this.$emit("mounted");
    if (this.isApiCall && this.firstApiLoad === false) {
      await this.fetchItemsFromAPI();
    }
  },
  watch: {
    reset() {
      this.resetValues();
    },
  },
};
</script>
