var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('DashboardHeader'),_c('DashboardNavigation'),_c('LayoutMessages'),_c('v-main',{staticClass:"horizontal-hidden-scroll",staticStyle:{"padding-left":"56px","overflow-y":"hidden"}},[_c('DashboardAnnouncements'),(_vm.isAdminPage)?[(!_vm.showLoader)?_c('div',{class:{
          white: !_vm.$route.meta['disableWhiteBackground'],
        },staticStyle:{"height":"calc(100vh - 70px)"}},[_vm._t("default")],2):_c('div',{staticClass:"d-flex flex-column justify-center align-center white",staticStyle:{"height":"calc(100vh - 70px)"}},[_c('v-progress-circular',{attrs:{"size":130,"width":10,"color":"primary","indeterminate":""}}),_c('p',{staticClass:"mt-8"},[_vm._v("We are loading your data, please hold on...")])],1)]:_c('v-container',{staticClass:"px-0 py-0",class:{
        'px-0': _vm.$route.meta['disablePadding'],
        'py-0': _vm.$route.meta['disablePadding'],
        'px-6': !_vm.$route.meta['disablePadding'],
        'py-6': !_vm.$route.meta['disablePadding'],
      },staticStyle:{"height":"calc(100vh - 64px)"},style:({
        overflow: _vm.$route.meta['disableScroll'] ? 'hidden' : 'auto',
      }),attrs:{"fluid":""}},[(!_vm.showLoader)?[_vm._t("default")]:_c('div',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"height":"calc(100vh - (70px + 32px + 32px))"}},[_c('v-progress-circular',{attrs:{"size":130,"width":10,"color":"primary","indeterminate":""}}),_c('p',{staticClass:"mt-8"},[_vm._v("We are loading your data, please hold on...")])],1)],2),_c('Support'),_c('UsersSurvey')],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }